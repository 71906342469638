import React from "react";
import Title from "../../title";
import SingleTestimony from "./SingleTestimony";
import style from "./Testimony.module.scss";

export default function Testimonies() {
  const testimonies = [
    {
      person: "Asche",
      description: "very well service",
      image:
        "https://cdn.pixabay.com/photo/2015/09/18/00/24/robin-944887__480.jpg",
    },
    {
      person: "Asche",
      description: "very well service",
      image:
        "https://cdn.pixabay.com/photo/2019/11/19/06/02/seascape-4636549__340.jpg",
    },
    {
      person: "Asche",
      description: "very well service",
      image:
        "https://cdn.pixabay.com/photo/2019/09/29/14/38/youth-4513448__340.jpg",
    },
    {
      person: "Asche",
      description: "very well service",
      image:
        "https://cdn.pixabay.com/photo/2016/11/29/13/14/attractive-1869761__480.jpg",
    },
  ];

  return (
    <section>
      <Title>
        <h2>Testimony</h2>
      </Title>
      <article className={style.testimonies}>
        {testimonies.map((testimony) => (
          <SingleTestimony testimony={testimony} />
        ))}
      </article>
    </section>
  );
}
