// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_title__KrurI {\n  font-size: 20px;\n  padding: 10px 0;\n  text-align: center;\n  width: 100%; }\n  .title_title__KrurI .title_icon__VYDWo {\n    margin: 0 7px; }\n", "",{"version":3,"sources":["webpack://./src/components/title/title.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,WAAW,EAAA;EAJb;IAOI,aAAa,EAAA","sourcesContent":[".title {\n  font-size: 20px;\n  padding: 10px 0;\n  text-align: center;\n  width: 100%;\n\n  .icon {\n    margin: 0 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_title__KrurI",
	"icon": "title_icon__VYDWo"
};
export default ___CSS_LOADER_EXPORT___;
