import React, { Component } from "react";
import style from "./checkbox.module.scss";
import Icon from "../../icon";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: Boolean(props.defaultValue),
    };
  }
  
  toggleChecke() {
    const { onChange } = this.props,
      { checked } = this.state;
    if (onChange) onChange(!checked);
    onChange(!checked);
    this.setState({ checked: !checked });
  }

  render() {
    const { label, switchMode } = this.props,
      { checked } = this.state;
    return (
      <span
        className={`${style[switchMode ? "switch" : "checkbox"]} ${
          checked ? style.checked : ""
        }`}
        onClick={this.toggleChecke.bind(this)}
      >
        <span className={style.checkboxCircle}>
          {switchMode ? null : <Icon name="check" />}
        </span>
        <label>{label}</label>
      </span>
    );
  }
}

export default Checkbox;
