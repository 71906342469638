import Businesses from "./pages/businesses";
import Deliveries from "./pages/deliveries";
import DeliveryDashboard from "./pages/deliveries/deliveryDashboard";
import Employees from "./pages/employees";
import Home from "./pages/home";
import NonLogedHome from "./pages/NonLogedIn/home";
import Joinus from "./pages/NonLogedIn/Joinus";
import Login from "./pages/NonLogedIn/login";
import Order from "./pages/orders";

export const routesList = {
  routes: [
    {
      name: "דשבורד",
      icon: "home",
      route: "/",
      component: Home,
    },
    {
      name: "הזמנות",
      icon: "truck",
      route: "/orders",
      component: Order,
    },
    {
      name: "הובלות",
      icon: "truck",
      route: "/deliveries",
      component: Deliveries,
    },
    {
      name: "דשבורד הובלות",
      icon: "dashboard",
      route: "/deliverydashbord",
      component: DeliveryDashboard,
    },
    {
      name: "עובדים",
      icon: "user",
      route: "/employees",
      component: Employees,
    },
    {
      name: "עסקים",
      icon: "building",
      route: "/business",
      component: Businesses,
    },
    {
      name: "דוחות",
      icon: "line-chart",
      route: "/",
      component: Home,
    },
    {
      name: "הגדרות",
      icon: "cog",
      route: "/",
      component: Home,
    },
  ],
  nonLogin: [
    { name: "בית", icon: "", route: "/", component: NonLogedHome },
    { name: "התחבר", icon: "", route: "/login", component: Login },
    { name: "הצטרף", icon: "", route: "/join/:type", component: Joinus },
  ],
};
