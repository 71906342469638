import React from "react";
import Tag from "../components/tag";
// import { imgsUrl } from "Config";

export const contentByType = (obj, { key = "", custom, type }, textOnly) => {
  const brokenHead = key.split("."),
    val =
      typeof obj != "object" || !key
        ? obj
        : brokenHead.reduce((acc, curr) => acc[curr] || "", obj);

  if (custom) return custom(val, obj, textOnly);

  if (key === "image" || key === "image url") type = "image";
  switch (type) {
    case "image":
    case "image url":
      return (
        <div className="imageDiv">
          <img src={val} />
        </div>
      );
    case "pdf":
      // return <object data={imagesUrl(val)} type="application/pdf" width="100%" height="100%"></object>;
      return (
        <object
          data="http://africau.edu/images/default/sample.pdf"
          type="application/pdf"
          width="100%"
          height="10%"
        >
          <p>
            Alternative text - include a link{" "}
            <a href="http://africau.edu/images/default/sample.pdf">
              to the PDF!
            </a>
          </p>
        </object>
      );
    case "address":
      return addressToString(val);
    case "string":
      if (typeof val === "object") {
        return objToString(val);
      }

      return String(val);
    case "tr":
      return val;
    case "number":
      if (textOnly) return val;
      try {
        return Number(val).toFixed(2);
      } catch (e) {}
      break;
    case "nis":
      if (textOnly) return val;
      try {
        if (key === "surfaces") {
          return `₪${Number(val * 160).toFixed(2)}`;
        }
        return `₪${Number(val).toFixed(2)}`;
      } catch (e) {}
      break;
    case "boolean":
      return textOnly ? val : val ? "✓" : "✗";
    case "date":
    case "dateTime":
      let dateString = _dateToString(val, type === "dateTime", !textOnly);
      return textOnly ? (
        dateString
      ) : (
        <div style={{ minWidth: "100px", textTransform: "capitalize" }}>
          {dateString}
        </div>
      );
    case "datesRange":
      let start = _dateToString(
          val.start || val.startDate,
          type === "dateTime",
          !textOnly
        ),
        end = _dateToString(
          val.end || val.endDate,
          type === "dateTime",
          !textOnly
        );
      return `${end} - ${start}`;
    case "time":
      const date = new Date(val);
      return date.toLocaleTimeString("he", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    case "tag":
    case "tags":
      return (type === "tag" ? [val] : val || []).map((v) =>
        textOnly ? `${v.name},` : <Tag text={v.name} />
      );
    default:
      return val;
  }
};

export const addressToString = ({
  city = "",
  street = "",
  building = "",
  apartment = "",
}) =>
  `${street} ${building}${apartment ? `/${apartment}` : ""}${
    street && city ? ", " : ""
  }${city}`;
export const objToString = (obj = {}) =>
  Object.keys(obj).map((key) => `${obj[key]}`);

const changeDay = (num = 0, date = new Date()) =>
  new Date(date.setDate(new Date().getDate() + num)).toDateString();
export const _dateToString = (date, time = true, format = true) => {
  date = new Date(date);
  if (!date || date === "Invalid Date") return "";
  let str = date
    .toLocaleDateString("he", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    .replace(/\./g, "/");
  if (format)
    switch (date.toDateString()) {
      case changeDay():
        str = "today";
        break;
      case changeDay(1):
        str = "tomorrow";
        break;
      case changeDay(-1):
        str = "yesterday";
        break;
    }
  if (time)
    str += `, ${date.toLocaleTimeString("he", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })}`;
  return str;
};

// export const imagesUrl = (name) => (name ? `${imgsUrl}${name}` : "");
// export const imagesUrl = name => {
//     try {
//         const img = require(`../components/img/images/${name}`)
//         return img
//     } catch (error) {
//         try {
//             const img = require(`../components/img/images/${name}.png`)
//             return img
//         } catch (error) {
//             return ""
//         }
//     return  ""
//     }
// }
