import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.authToken;
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const info = {
  entity: localStorage.entity || undefined,
  lang: localStorage.languageKey || "he",
  platform: "admin",
};

export const httpRequestServise = async (
  url,
  method = "get",
  data,
  options = {}
) => {
  let response;
  switch (method) {
    case "post":
    case "patch":
      try {
        const formData = prepareData(data);
        response = await axios[`${method}`](url, formData, {
          params: { ...info, ...options },
        });
        return response.data;
      } catch (error) {
        throw error;
      }

    default:
      try {
        response = await axios[method](url, {
          params: { ...info, ...options },
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return error;
      }
  }
};

const prepareData = (data) => {
  const formatedData = Object.assign({}, data);
  if (data.files) {
    const fData = new FormData();
    Object.keys(data.files).forEach((fileName) => {
      const curr = data.files[fileName];
      if (Array.isArray(curr))
        curr.forEach((c) => fData.append("files", c, fileName));
      else fData.append("files", curr, fileName);
    });
    fData.append(
      "fData",
      JSON.stringify({ ...formatedData, files: undefined })
    );
    return fData;
  }
  return formatedData;
};
