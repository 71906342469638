// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon_wrapper__sKPM6 {\n  display: inline-block; }\n  .icon_wrapper__sKPM6 .icon_icon__OHJm4 {\n    color: #2f97ba;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);\n    box-sizing: content-box; }\n", "",{"version":3,"sources":["webpack://./src/components/icon/icon.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,qBAAqB,EAAA;EADvB;IAII,cCNY;IDOZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2CCDuC;IDEvC,uBAAuB,EAAA","sourcesContent":["@import \"src/variables.scss\";\n\n.wrapper {\n  display: inline-block;\n\n  .icon {\n    color: $color1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-shadow: $textShadow;\n    box-sizing: content-box;\n  }\n}\n","$color1: #2f97ba;\n$color2: #1a1b21;\n$errorColor: #ca0000;\n$borderRadius: 10px;\n$isTablet: \"screen and (max-width: 1200px)\";\n$isMobile: \"screen and (max-width: 800px)\";\n$shadow: 0px 3px 20px 3px rgba(0, 0, 0, 0.14);\n$smallShadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),\n  0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);\n$textShadow: 4px 4px 5px rgba(0, 0, 0, 0.2);\n$menuWidth: 220px;\n$mainWidth: 1400px;\n$side: right;\n$opSide: left;\n$direction: rtl;\n$inputHeight:30px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "icon_wrapper__sKPM6",
	"icon": "icon_icon__OHJm4"
};
export default ___CSS_LOADER_EXPORT___;
