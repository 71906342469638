import React, { Component } from "react";
import { connect } from "react-redux";
import style from "./input.module.scss";
// import Icon from "../../icon";
// import Tag from "../../tag";

// class Input extends Component {
//   constructor(props) {
//     super(props);
//     this.multiple = props.tagsInput || (props.autocomplete || {}).multiple;
//     this.state = {
//       selected: -1,
//       tags: this.multiple ? props.defaultValue : [],
//     };
//   }

//   componentWillReceiveProps(nextProps) {
//     if (
//       this.props.options &&
//       JSON.stringify(this.props.options) !=
//         JSON.stringify(nextProps.options || null)
//     ) {
//       this.setState({
//         autocompleteResults: nextProps.options,
//         autocompleteOpen: Boolean(nextProps.options.length),
//         selected: -1,
//       });
//       this.refs.input.value = "";
//     }
//   }
//   onChange(e) {
//     const { onChange, onChange, autocomplete, tagsInput, options } =
//       this.props;
//     let { value } = e.target;
//     if (onChange && !options) onChange(e);
//     if (!tagsInput) {
//       if (options) {
//         const list =
//           /* value ? options.filter(o => (o.text || o).includes(value)) : */ options;
//         this.setState({
//           autocompleteResults: list,
//           autocompleteOpen: Boolean(list.length),
//           selected: -1,
//         });
//       } else if (autocomplete) {
//         clearTimeout(this.timeout);
//         value = value.trim().replace(/[&\/\\#,+()!@$~%.":*?<>{}]/g, "");
//         /* if (!value || !value.length) this.setState({ autocompleteResults: [] })
//                 else */ this.timeout = setTimeout(
//           () => this.autocomplete(value),
//           300
//         );
//       } else if (onChange) onChange(value);
//     }
//   }
//   autocomplete(value) {
//     const {
//       autocomplete: { collection },
//       remoteAction,
//     } = this.props;
//     if (collection)
//       // debugger
//       remoteAction(
//         collection,
//         "filter",
//         { search: value, limit: 50 },
//         { noDispath: true }
//       ).then(({ data }) =>
//         this.setState({
//           autocompleteResults: data,
//           autocompleteOpen: Boolean(data.length),
//           selected: -1,
//         })
//       );
//   }
//   autocompleteResults() {
//     const { autocomplete, options } = this.props;
//     // debugger
//     let { autocompleteResults = [], selected, tags = [] } = this.state;
//     if (this.multiple)
//       autocompleteResults = autocompleteResults.filter(
//         (a) => !tags.find((t) => t.id == a.id)
//       );
//     return autocompleteResults.map((r, i) => (
//       <div
//         className={`${style.autocompleteItem} ${
//           i == selected ? style.selected : ""
//         }`}
//         key={r.id || r.value}
//         onClick={() => this.onSelect(r)}
//       >
//         {r[options ? "text" : autocomplete.field || "name"]}
//       </div>
//     ));
//   }
//   onKeyDown(e) {
//     const { tagsInput, autocomplete } = this.props,
//       { selected, autocompleteResults = [] } = this.state;
//     if (tagsInput) {
//       if (e.which == 13 && e.target.value) {
//         e.preventDefault();
//         this.onSelect(e.target.value.trim());
//       }
//     } else if (autocomplete) {
//       switch (e.which) {
//         case 38:
//           this.setState({
//             selected:
//               selected <= 0 ? autocompleteResults.length - 1 : selected - 1,
//           });
//           break;
//         case 40:
//           this.setState({
//             selected:
//               selected >= autocompleteResults.length - 1 ? 0 : selected + 1,
//           });
//           break;
//         case 13:
//           if (selected >= 0) {
//             e.preventDefault();
//             this.onSelect(autocompleteResults[selected]);
//           }
//           break;
//         default:
//           break;
//       }
//     }
//   }
//   onSelect(selected) {
//     const {
//       onChange,
//       onChange,
//       autocomplete = {},
//       tagsInput,
//       options,
//       clearNote,
//     } = this.props;
//     let { tags = [] } = this.state;
//     tags = [...tags, selected].filter((t) => t);

//     if (onChange) {
//       const v = this.multiple
//         ? tags.map((t) =>
//             tagsInput ? t : t[this.autocomplete.value || "id"] || "_id"
//           )
//         : selected[
//             options ? "value" : this.autocomplete.value || "id" || "_id"
//           ];
//       onChange(v);
//       if (onChange) onChange(v);
//     }

//     this.refs.input.value = this.multiple
//       ? ""
//       : selected[options ? "text" : autocomplete.field || "name"];

//     this.setState({ autocompleteOpen: false, tags, selected: -1 });
//     clearNote();
//   }
//   getTags() {
//     const { autocomplete, onChange, tagsInput } = this.props,
//       { tags = [] } = this.state;
//     return (
//       <div className={style.tags}>
//         {tags.map((a) => (
//           <Tag
//             text={tagsInput ? a : a[autocomplete.field || "name"]}
//             onDelete={(text) => {
//               const newTags = tags.filter(
//                 (t) => (tagsInput ? t : t[autocomplete.field || "name"]) != text
//               );
//               onChange(
//                 newTags.map((t) =>
//                   tagsInput ? t : t[autocomplete.value || "id"] || "_id"
//                 )
//               );
//               this.setState({ tags: newTags });
//             }}
//           />
//         ))}
//       </div>
//     );
//   }
//   getDefaultValue() {
//     const { defaultValue, autocomplete, tagsInput, options } = this.props;
//     if (tagsInput) return "";
//     // debugger
//     if (autocomplete) return (defaultValue || {})[autocomplete.field || "name"];
//     if (options)
//       return (options.find((o) => o.value == defaultValue) || {}).text;
//     return defaultValue;
//   }

//   setTagsGoogleAutocomplete(value) {
//     const { onChange } = this.props;
//     this.setState({ tags: [...(this.state.tags || ""), value] });
//     onChange(this.state.tags);
//   }

//   render() {
//     const { icon, autocomplete, required, forSurfaces, min } =
//         this.props,
//       { autocompleteOpen, tags = [] } = this.state;
//     return (
//       <span className={style.input}>
//         <div className={`${style.relative}  ${icon ? style.iconInput : ""}`}>
//           <Icon name={icon} className={style.icon} />
//           <input
//             className={` ${forSurfaces ? style.selectSurfaces : ""}`}
//             {...Object.assign({}, this.props, { onChange: undefined })}
//             autoComplete={autocomplete ? "off" : undefined}
//             style={{ width: forSurfaces ? "100%" : "" }}
//             onChange={this.onChange.bind(this)}
//             onFocus={this.onChange.bind(this)}
//             onKeyDown={this.onKeyDown.bind(this)}
//             defaultValue={this.getDefaultValue()}
//             required={this.multiple ? required && !tags.length : required}
//             min={min}
//           />
//         </div>
//       </span>
//     );
//   }
// }

function Input(props) {
  const { forSurfaces, icon, onChange } = props;
  const inputProps = { ...props };
  delete inputProps.clearNote;

  const changeHandler = (e) => {
    onChange(e.target.value);
  };

  return (
    <span className={style.input}>
      <div className={`${style.relative}  ${icon ? style.iconInput : ""}`}>
        <input
          {...inputProps}
          onChange={changeHandler}
          className={` ${forSurfaces ? style.selectSurfaces : ""}`}
          style={{ width: forSurfaces ? "100%" : "" }}
        />
      </div>
    </span>
  );
}
export default Input;
// export default connect((state) => ({}), {})(Input);
