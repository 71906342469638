import React, { useEffect } from "react";
import Table from "../../components/table";
import { employeeFields } from "../../fields/employee";
import EmployeeForm from "./EmployeeForm";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../../redux/reducers/employee";

function Employees() {
  const dispatch = useDispatch();
  const { employees } = useSelector((state) => state.employees);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getEmployees());
  }, []);
// { business: user.business.id }
  return (
    <div>
      <Table
        data={employees}
        fields={employeeFields}
        AddEditComponent={EmployeeForm}
        collection="employee"
      />
    </div>
  );
}

export default Employees;
