import React from "react";
import { Link } from "react-router-dom";
import style from "./services.module.scss";

export default function SingleService({ service }) {
  return (
    <div className={style.service}>
      <div className={style.inner}>
        {/* <Icon name={service.icon} style={{fontSize:"40px"}} size="40"/> */}
        <img src={service.image} alt={service.title} />
        <div className={style.textDescription}>
          <h2>{service.title}</h2>
          <p>{service.description}</p>
          <button className={style.actionButton}>
            <Link to={service.button.link}>{service.button.text}</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
