import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import { httpRequestServise } from "../../services/http.service";

export const getUserById = createAsyncThunk("nema", async () => {
  const { authToken } = localStorage;
  if (!authToken) {
    return {};
  }
  const data = await httpRequestServise(`${baseUrl}/user/me`, "get");
  return data;
});

export const getSmsCode =
  (phone) =>
  async () => {
    const data = await httpRequestServise(`${baseUrl}/get-sms-code`, "post", {
      phone,
    });
    return data;
  };

export const verifySmsCode = createAsyncThunk("name", async (data) => {
  const { phone, code} = data;
  return await httpRequestServise(`${baseUrl}/verify-sms-code`, "post", {
    phone,
    smsCode: code,
  });
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    isLoading: true,
  },
  extraReducers: {
    [getUserById.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      // console.log(payload.user);
      state.user = payload.user;
      return state;
    },
    [verifySmsCode.fulfilled]: (state, { payload }) => {
      const { authToken, user, entity } = payload;
      localStorage.authToken = authToken;
      localStorage.uid = user.id;
      localStorage.entity = entity;
      localStorage.business = user.business?.name || user.name;
      state.user = user;
      return state;
    },
  },
});

export default userSlice.reducer;
