import React, { Component, useState } from "react";
import style from "./modal.module.scss";
import Icon from "../icon";
import Title from "../title";

function Modal(props) {
  const { content, options = {} } = props;
  const [hide, setHide] = useState(false);
  const { title, bgImgUrl, fullScreen, noPadding, xClose } = options;

  const closeModal = (e) => {
    if (e.target !== e.currentTarget) return;
    setHide(true);
  };

  return (
    <div
      onClick={xClose ? undefined : closeModal}
      className={`${style.modalWrapper} ${style.open} ${
        hide ? style.hide : ""
      } ${noPadding ? style.noPaddings : ""} ${
        fullScreen ? style.fullScreen : ""
      }`}
    >
      <div
        className={style.modal}
        style={bgImgUrl ? { background: `url(${bgImgUrl})` } : {}}
      >
        {title && <Title className={style.title}>{title}</Title>}
        <div className={style.content}>{content}</div>
      </div>
    </div>
  );
}

export default Modal;
