import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default function BarChart(props) {
  const { data, total, label } = props;
  const state = {
    labels: data.map((df) => df._id) || [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: `${label} ${total ? total : ""}`,
        backgroundColor: "#2f97ba",
        borderColor: "#1a1b21",
        borderWidth: 2,
        data: data.map((df) => df.count),
      },
    ],
  };

  return (
    // <div style={{ height: "50vh" }}>
    <Bar
      data={state}
      height={'80px'}
      options={{
        title: {
          display: true,
          text: "Average Rainfall per month",
          fontSize: 20,
        },
        legend: {
          display: true,
          position: "right",
          // xdescribe,
        },
      }}
    />
    // </div>
  );
}
