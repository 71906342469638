import React, { useEffect } from "react";
import Table from "../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { getBusineses } from "../../redux/reducers/business";
import { businessFields } from "../../fields/business";

function Businesses() {
  const dispatch = useDispatch();
  const { businesses } = useSelector((state) => state.business);
  useEffect(() => {
    dispatch(getBusineses());
  }, []);
  return (
    <div>
      <Table
        data={businesses}
        fields={businessFields}
        AddEditComponent={null}
        collection="business"
        extra={true}
      />
    </div>
  );
}

export default Businesses;
