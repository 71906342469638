import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "../../components/table";
import Title from "../../components/title";
import { baseUrl } from "../../config";
import { deliveryFields } from "../../fields/delivery";
import { httpRequestServise } from "../../services/http.service";
import DeliveryForm from "../deliveries/DeliveryForm";
import style from "./orders.module.scss";

export default function SingleOrder() {
  const [order, setOrder] = useState({});
  const [formValue, setFormValue] = useState(order);
  const { id } = useParams();

  useEffect(() => {
    getOrder();
  }, [id]);

  const getOrder = async () => {
    const data = await httpRequestServise(`${baseUrl}/order/${id}`);
    data.deliveries.map((delivery) => {
      delivery.deliveryCompany = delivery.deliveryCompany?.name;
      delivery.driver = delivery.driver?.name;
    });
    setOrder(data);
  };

  const submitHandler = (values) => {
    // if (action === "create") dispatch(addNewDelivery(values));
    // else dispatch(updateDeliveryById({ id: data.id, body: formValue }));
  };

  return (
    <div>
      <section
        className={style.form}
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   submitHandler(formValue);
        // }}
      >
        <div>
          <h3 htmlFor="pickupAddress">כתובת איסוף*</h3>
          <input
            type="text"
            defaultValue={order["pickupAddress"]}
            name="pickupAddress"
            disabled
          />
        </div>
        <div>
          <label htmlFor="deliveryAddress">כתובת מסירה*</label>
          <input
            type="text"
            defaultValue={order["deliveryAddress"]}
            name="deliveryAddress"
            disabled
          />
        </div>

        <div>
          <label htmlFor="sum"> מחיר (₪)</label>
          <input
            type="text"
            defaultValue={order["sum"]}
            name="sum"
            onChange={(e) => setFormValue({ ...order, sum: e.target.value })}
            disabled
          />
        </div>
        <div className={style.customer}>
          <label htmlFor="">שם לקוח</label>
          <input type="text" defaultValue={order["customer"]?.name} disabled />
        </div>
      </section>
      <Title>
        <h2>רשימת הובלות להזמנה זו</h2>
      </Title>

      <Table
        data={order.deliveries}
        AddEditComponent={DeliveryForm}
        fields={deliveryFields}
        collection="order"
        fromSingle={order}
      />
    </div>
  );
}
