import React from "react";
import style from "./Testimony.module.scss";

export default function SingleTestimony({ testimony }) {
  return (
    <div className={style.testimony}>
      <h3>{testimony.person}</h3>
      <img src={testimony.image} alt="" />
      <p className={style.description}>{testimony.description}</p>
    </div>
  );
}
