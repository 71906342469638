const businessFields = {
  id: {
    type: "text",
    required: true,
    hide: true,
    hideOnTable: true,
    tableType: "string",
  },
  name: {
    type: "text",
    name: "name",
    label: "שם",
    required: true,
    tableType: "string",
  },
  type: {
    type: "text",
    name: "type",
    label: "סוג",
    required: true,
    tableType: "string",
  },
  phone: {
    type: "text",
    name: "phone",
    label: "טלפון",
    required: true,
    tableType: "string",
  },
  profileImage: {
    type: "file",
    name: "profileImage",
    label: "תמונת פרופיל",
    tableType: "image",
  },
};

Object.keys(businessFields).map((key) => (businessFields[key].name = key));

export { businessFields };
