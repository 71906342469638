import React, { Component } from 'react';
import style from './fileInput.module.scss';
// import { imagesUrl } from 'Functions';

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgPreview: props.defaultValue ? `${props.defaultValue}` : undefined,
    };
  }
  onChange(input) {
    const { accept, onChange } = this.props;
    if (accept == 'image' || accept == 'file') {
      const reader = new FileReader();

      reader.onload = (e) => this.setState({ imgPreview: e.target.result });

      reader.readAsDataURL(input.files[0]);
    } else this.setState({ fileNamePreview: input.files[0].name });

    if (onChange) onChange(input.files[0]);
  }
  render() {
    const { className = '', accept } = this.props,
      { imgPreview, fileNamePreview } = this.state;

    return (
      <div className={`${style.fileInput} ${className}`}>
        {imgPreview || fileNamePreview ? null : <span>לחץ כאן כדי להעלות {accept == 'image' || accept == 'file' ? 'תמונה' : 'קובץ'}</span>}
        {accept == 'image' || 'file' ? imgPreview ? <img src={imgPreview} /> : null : fileNamePreview ? <span>{fileNamePreview}</span> : null}
        <input type="file" accept={accept ? `${accept}/*` : undefined} onChange={(e) => this.onChange(e.target)} />
      </div>
    );
  }
}

export default FileInput;
