import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import { httpRequestServise } from "../../services/http.service";

export const getBusineses = createAsyncThunk(
  "get businesses list",
  async (filter={}) => {
    return httpRequestServise(`${baseUrl}/business`, "get", {}, { ...filter });
  }
);
export const getDeliveryCopms = createAsyncThunk(
  "get busineses",
  async (filter) => {
    return httpRequestServise(`${baseUrl}/business`, "get", {}, { ...filter });
  }
);

const businessSlices = createSlice({
  name: "business",
  initialState: {
    deliveryComps: [],
    businesses: [],
  },
  extraReducers: {
    [getBusineses.fulfilled]: (state, { payload }) => {
      state.businesses = payload;
    },
    [getDeliveryCopms.fulfilled]: (state, { payload }) => {
      state.deliveryComps = payload;
    },
  },
});

export default businessSlices.reducer;
