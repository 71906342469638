import React from "react";
import Footer from "../../../components/NonLoggedin/Footer";
import MainHeader from "../../../components/NonLoggedin/Header";
import Join from "../../../components/NonLoggedin/Join";
import Services from "../../../components/NonLoggedin/services";
import Testimonies from "../../../components/NonLoggedin/Testimony";
import style from "./home.module.scss";
export default function NonLogedHome() {
  const workingWith = [
    "afil.png",
    "ampm.png",
    "cocacola.png",
    "gindi.png",
    "homecenter.png",
    "shufersal.png",
    "terminalX.png",
  ];
  const services = [
    { title: "משטחים", image: "/assets/delivery/chuttersnap-JWa.png" },
    { title: "מכולות", image: "/assets/delivery/frank-mckenna-t.png" },
    { title: "קווי חלוקה", image: "/assets/delivery/close-up-hands-.png" },
    { title: "מנופים", image: "/assets/delivery/fons-heijnsbroe.png" },
    { title: "אחרות", image: "/assets/delivery/truck-driver-in.png" },
  ];
  return (
    <>
      <MainHeader />
      <Services />

      <section className={style.services}>
        <h2>
          <center>פשוט מובילים הכל! מה תרצו להוביל?</center>
        </h2>
        <div className={style.servicesList}>
          {services.map((service) => {
            return (
              <article>
                <img src={service.image} alt={service.title} />
                <h5>{service.title}</h5>
              </article>
            );
          })}
        </div>
      </section>
      <section className={style.workingWith}>
        <h2>
          {" "}
          <center>חברות שבחרו בנו</center>
        </h2>
        <article className={style.list}>
          {workingWith.map((img) => (
            <img src={`/assets/workingWith/${img}`} alt={img} />
          ))}
        </article>
      </section>
      {/* <Join /> */}
      <Footer />
    </>
  );
}
