import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import { httpRequestServise } from "../../services/http.service";

export const getDeliveries = createAsyncThunk(
  "getDeliveries request",
  async (filter = {}) => {
    return httpRequestServise(`${baseUrl}/delivery`, "get", {}, filter);
  }
);

export const addNewDelivery = createAsyncThunk("request name", async (body) => {
  return httpRequestServise(`${baseUrl}/delivery`, "post", body);
});

export const updateDeliveryById = createAsyncThunk(
  "request name",
  async ({ id, body }) => {
    return httpRequestServise(`${baseUrl}/delivery/${id}`, "patch", body);
  }
);

const deliverySlice = createSlice({
  name: "deliveries",
  initialState: {
    loading: true,
    deliveries: [],
  },
  reducers: {
    newDeliveryBySocket: (state, { payload }) => {
      state.deliveries = [...state.deliveries, payload];
      return state;
    },
  },
  extraReducers: {
    [getDeliveries.fulfilled]: (state, { payload }) => {
      state.deliveries = payload;
      state.deliveries?.map((delivery) => {
        delivery.deliveryCompany = delivery.deliveryCompany?.name;
        delivery.customer = delivery.customer?.name;
        // delivery.customer = delivery.order?.customer;
        // delivery.driver = {
        //   text: delivery.driver?.name,
        //   value: delivery.driver?.id,
        // };
        return delivery;
      });
      return state;
    },
    [addNewDelivery.fulfilled]: (state, { payload }) => {
      state.deliveries = [...state.deliveries, payload];
      return true;
    },
    [updateDeliveryById.fulfilled]: (state, { payload }) => {
      const index = state.deliveries.indexOf((item) => item.id === payload.id);
      state.deliveries[index] = payload;
      return state;
    },
  },
});

export const { newDeliveryBySocket } = deliverySlice.actions;

export default deliverySlice.reducer;
