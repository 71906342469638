const orderFields = {
  id: { tableType: "string", type: "text", hide: true, hideOnTable: true },
  active: {
    type: "switch",
    default: true,
    tableType: "boolean",
    label: "פעיל",
    formStep: 1,
  },
  sum: {
    type: "number",
    default: 0,
    tableType: "sum",
    label: "מחיר",
    hide: true,
  },
  deliveryNumber: {
    type: "number",
    tableType: "number",
    label: "מזהה הובלה",
    hide: true,
  },
  pickupAddress: {
    tableType: "string",
    type: "address",
    required: true,
    formStep: 2,
    label: "כתובת איסוף",
  },
  deliveryAddress: {
    tableType: "string",
    type: "address",
    required: true,
    formStep: 3,
    label: "כתובת יעד",
  },
  shippingContent: {
    type: "group",
    formStep: 4,
    label: "shipping Content",

    members: {
      surfacesAmount: {
        type: "number",
        tableType: "number",
        name: "surfacesAmount",
        label: "surfaces Amount",
      },
    },

    hideOnTable: true,
  },
  surfacesAmount: {
    type: "number",
    tableType: "number",
    hide: true,
    label: "fragile Amount",
    hideOnTable: true,
  },
  fragileContents: {
    type: "switch",
    tableType: "boolean",
    hide: true,
    label: "fragile Contents",
    hideOnTable: true,
  },
  deliveryTime: { type: "group", required: true, formStep: 5 },
  pickupAddressplaceId: {
    tableType: "string",
    type: "text",
    hide: true,
    hideOnTable: true,
  },
  deliveryAddressplaceId: {
    tableType: "string",
    type: "text",
    hide: true,
    hideOnTable: true,
  },
  status: {
    type: "select",
    tableType: "string",
    options: [
      { text: "חדש", value: "new" },
      { text: "בתהליך", value: "businessSelected" },
      { text: "הסתיים", value: "driverAssined" },
    ],
    default: "new",
    hide: true,
    label: "סטטוס",
  },
  userPhone: {
    tableType: "string",
    type: "text",
    hide: true,
    status: "user phone",
    hideOnTable: true,
  },

  distance: {
    type: "string",
    tableType: "string",
    hide: true,
    label: "מרחק",
  },
  duration: {
    type: "string",
    tableType: "string",
    hide: true,
    label: "זמן הגעה",
  },
};

Object.keys(orderFields).map((key) => (orderFields[key].name = key));

export { orderFields };
