// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fileInput_fileInput__U8QIG {\n  position: relative;\n  min-height: 50px;\n  border: 1px dotted gray;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: auto; }\n  .fileInput_fileInput__U8QIG span {\n    max-width: 90%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: gray;\n    min-width: 200px;\n    text-align: center; }\n  .fileInput_fileInput__U8QIG img {\n    max-height: 150px;\n    min-width: 100px; }\n  .fileInput_fileInput__U8QIG input {\n    cursor: pointer;\n    position: absolute;\n    right: 0;\n    top: 0;\n    width: 100%;\n    min-width: 100%;\n    opacity: 0;\n    height: 100%; }\n", "",{"version":3,"sources":["webpack://./src/components/inputs/fileInput/fileInput.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,EAAA;EAPd;IAUI,cAAc;IACd,gBAAgB;IAChB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,kBAAkB,EAAA;EAftB;IAmBI,iBAAiB;IACjB,gBAAgB,EAAA;EApBpB;IAwBI,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,WAAW;IACX,eAAe;IACf,UAAU;IACV,YAAY,EAAA","sourcesContent":["\n.fileInput {\n  position: relative;\n  min-height: 50px;\n  border: 1px dotted gray;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: auto;\n\n  span {\n    max-width: 90%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: gray;\n    min-width: 200px;\n    text-align: center;\n  }\n\n  img {\n    max-height: 150px;\n    min-width: 100px;\n  }\n\n  input {\n    cursor: pointer;\n    position: absolute;\n    right: 0;\n    top: 0;\n    width: 100%;\n    min-width: 100%;\n    opacity: 0;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": "fileInput_fileInput__U8QIG"
};
export default ___CSS_LOADER_EXPORT___;
