import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleMap from "../../components/googleMap";
import { baseUrl } from "../../config";
import { deliveryFields } from "../../fields/delivery";
import { getDeliveryCopms } from "../../redux/reducers/business";
import {
  addNewDelivery,
  updateDeliveryById,
} from "../../redux/reducers/deliveries";
import { httpRequestServise } from "../../services/http.service";

import style from "./deliveries.module.scss";

export default function DeliveryForm(props) {
  const { data = {}, collection, action, fromSingle, setSelectedItem } = props;
  const dispatch = useDispatch();
  const { deliveryComps } = useSelector((state) => state.business);
  const [searchResult, setSearchResult] = useState(deliveryComps);

  const getDeliveryCompanies = () => {
    dispatch(getDeliveryCopms({ type: "delivery_company" }));
  };

  useEffect(() => {
    getDeliveryCompanies();
  }, []);

  const search = (e) => {
    const searchTerm = e.target.value;
    if (searchTerm) {
      httpRequestServise(
        `${baseUrl}/business/search/deliveryCompany`,
        "get",
        {},
        { searchTerm: e.target.value }
      ).then((result) => {
        // console.log(result);
        setSearchResult(result);
      });
    }
  };

  const submitHandler = async (values) => {
    let res;
    if (action === "create") {
      res = await dispatch(addNewDelivery(values));
    } else
      res = await dispatch(
        updateDeliveryById({ id: data.id, body: formValue })
      );
    if (res.meta?.requestStatus == "fulfilled") {
      setTimeout(() => setSelectedItem(null), 300);
    }
  };

  const [formValue, setFormValue] = useState(data);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "shippingContent.type":
        setFormValue({
          ...formValue,
          shippingContent: { ...formValue.shippingContent, type: value },
        });
        break;
      case "shippingContent.amount":
        setFormValue({
          ...formValue,
          shippingContent: { ...formValue.shippingContent, amount: value },
        });
        break;
      default:
        setFormValue({ ...formValue, [name]: value });
        break;
    }
  };

  useEffect(() => {
    if (fromSingle) {
      formValue.pickupAddressplaceId = fromSingle.pickupAddressplaceId;
      formValue.pickupAddress = fromSingle.pickupAddress;
      formValue.deliveryAddressplaceId = fromSingle.deliveryAddressplaceId;
      formValue.deliveryAddress = fromSingle.deliveryAddress;
      formValue.order = fromSingle.id;
      formValue.distance = fromSingle.distance;
      formValue.client = fromSingle.client;
      formValue.duration = fromSingle.duration;
      formValue.address = fromSingle.address;
    }
  }, [fromSingle]);

  return (
    <section style={{ display: "flex", flexDirection: "column" }}>
      <article style={{ flexGrow: 1, height: "200px" }}>
        <GoogleMap
          data={[
            {
              pickupAddressplaceId: formValue?.pickupAddressplaceId,
              deliveryAddressplaceId: formValue?.deliveryAddressplaceId,
            },
          ]}
        />
      </article>
      {/* {(collection === "delivery" && action === "update") ||
        (collection === "order" && ( */}
      <form
        className={style.form}
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(formValue);
        }}
      >
        <div>
          <label htmlFor="pickupAddress">כתובת איסוף*</label>
          <input
            type="text"
            defaultValue={formValue["pickupAddress"]}
            name="pickupAddress"
            disabled
          />
        </div>
        <div>
          <label htmlFor="deliveryAddress">כתובת מסירה*</label>
          <input
            type="text"
            defaultValue={formValue["deliveryAddress"]}
            name="deliveryAddress"
            disabled
          />
        </div>

        <div className={style.customer}>
          {/* <h4 style={{"textAlign":"center"}}>פרטי לקוח</h4> */}
          <label htmlFor="">שם לקוח</label>
          <input
            type="text"
            defaultValue={formValue["customer"]?.name}
            disabled
          />
        </div>
        <section>
          <h4>
            <center>פרטים ייחודיים להובלה זו</center>
          </h4>

          <div>
            <label htmlFor="sum"> מחיר (₪) הובלה </label>
            <input
              type="text"
              defaultValue={formValue["sum"]}
              name="sum"
              onChange={changeHandler}
              id="sum"
            />
          </div>
          <article>
            <h4>תכולה</h4>
            <div>
              <label htmlFor="shippingContent.type">סוג תכולה: </label>
              <select
                type="text"
                defaultValue={formValue["shippingContent"]?.type}
                name="shippingContent.type"
                onChange={changeHandler}
                id="shippingContent.type"
              >
                <option value="עפר">עפר</option>
                <option value="בטון">בטון</option>
              </select>
            </div>
            <div>
              <label htmlFor="hippingContent.amount">כמות תכולה</label>
              <input
                type="number"
                defaultValue={formValue["shippingContent"]?.amount}
                name="shippingContent.amount"
                onChange={changeHandler}
                id="hippingContent.amount"
              />
            </div>
          </article>
          <article>
            {/* <h4 htmlFor="">פרטי חברת הובלה</h4> */}
            {/* {!formValue.deliveryCompany ? ( */}
            <div>
              <label htmlFor="">חברת הובלה*</label>
              <input
                type="text"
                defaultValue={formValue.deliveryCompany}
                placeholder="חפש חברת שילוח..."
                onChange={search}
                disabled={formValue.driver || formValue.deliveryCompany}
              />
              <div className={style.companies}>
                {searchResult?.map((result) => (
                  <div
                    className={style.searchResult}
                    onClick={() =>
                      setFormValue({
                        ...formValue,
                        deliveryCompany: result,
                        status: "businessAssigned",
                      })
                    }
                  >
                    <p>{result.name}</p>
                    <img
                      src={result.logo || "/assets/icons/regular-truck.png"}
                      alt=""
                      className={style.logo}
                    />
                  </div>
                ))}
              </div>
              {formValue.deliveryCompany && (
                <button
                  onClick={() => {
                    alert("האם אתה בטוח שברצונך להסיר את החברת שלילויות")
                    setFormValue({ ...formValue, deliveryCompany: "" });
                  }}
                >
                  החלף חברת הובלה
                </button>
              )}
            </div>
            {/* ) : (
              <div>
                
              </div>
            )} */}
          </article>
        </section>
        <input className={style.submitBtn} type="submit" value="שמור" />
      </form>
      {/* ))} */}
    </section>
  );
}
