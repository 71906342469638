import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import { httpRequestServise } from "../../services/http.service";

export const getEmployees = createAsyncThunk("getEmployess", async (filter) => {
  return httpRequestServise(`${baseUrl}/employee`, "get", {}, filter);
});

export const addNewEmployee = createAsyncThunk("request name", async (body) => {
  return httpRequestServise(`${baseUrl}/employee`, "post", body);
});

export const updateEmployeeById = createAsyncThunk(
  "updateEmployeeById",
  async ({ id, values }) => {
    return httpRequestServise(`${baseUrl}/employee/${id}`, "patch", values);
  }
);

export const employeeSlice = createSlice({
  name: "employees",
  initialState: {
    status: "loading",
    isLoading: true,
    employees: [],
  },

  extraReducers: {
    [getEmployees.fulfilled]: (state, { payload }) => {
      state.employees = payload;
      state.employees.map((employee) => {
        employee.business = employee.business?.name;
        return employee;
      });
      return state;
    },
    [addNewEmployee.fulfilled]: (state, { payload }) => {
      state.employees = [...state.employees, payload];
      return state;
    },
    [updateEmployeeById.fulfilled]: (state, { payload }) => {
      const index = state.employees.indexOf((item) => item.id === payload.id);
      state.employees[index] = payload;
      return state;
    },
  },
});

export default employeeSlice.reducer;
