import React, { Component } from 'react'

class Textarea extends Component {
    constructor() {
        super()
        this.state = {}
    }
    render() {
        const { onChange } = this.props

        return <textarea rows='2' {...this.props} onChange={e => { if (onChange) onChange(e.target.value) }}></textarea>
    }
}

export default Textarea