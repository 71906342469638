import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {
    isMobile: window.innerWidth >= 650 ? false : true,
    mobileWidth: "650px",
    tabletWidth: "900px",
    httpRequest: {
      loading: false,
      error: false,
      message: "",
    },
    menuOpen: true,
  },

  reducers: {
    toggleMenu(state, { payload }) {
      if (typeof payload === "boolean") state.menuOpen = payload;
      else state.menuOpen = !state.menuOpen;
    },
    toggleIsMobile(state) {
      state.isMobile = !state.isMobile;
    },
    httpRequestState(state, { payload }) {
      state.httpRequest.loading = payload.loading;
    },
  },
});

export const { toggleMenu, toggleIsMobile,httpRequestState } = configSlice.actions;
export default configSlice.reducer;
