import React, { Component } from "react";
import style from "./form.module.scss";
import FormItem from "./formItem";

class GroupItem extends Component {
  constructor() {
    super();
    this.state = {};
    this.values = {};
  }

  render() {
    const {
      className = "",
      onChange,
      members,
      fields,
      name,
    } = this.props;
    return (
      <div className={`${style.formGroup} ${className}`}>
        {Object.keys(members)?.map((member) => {
          return (
            <FormItem
              key={this.values[member.name]}
              item={fields[name]["members"][member]}
              onChange={(val) => {
                onChange(val);
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default GroupItem;
