import React from 'react'
import iconStyle from './icon.module.scss'

const Icon = ({ name, className = '', style, onClick, spin, size = 15 }) => name ?
    <span className={`${iconStyle.wrapper} ${className}`}>
        <i aria-hidden='true' onClick={onClick}
            className={`fa fa-${name} ${iconStyle.icon} ${spin ? 'fa-spin' : ''}`}
            style={Object.assign(onClick ? { cursor: 'pointer' } : {}, { height: `${size}px`, width: `${size}px` }, style)}
        ></i>
        </span> : null
export default Icon
