import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import Form from "../../../components/form";
import { getSmsCode, verifySmsCode } from "../../../redux/reducers/user";
import Icon from "../../../components/icon";
import style from "./login.module.scss";

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onSubmitPhone(values) {
    const { getSmsCode } = this.props;
    getSmsCode(values.phone)
      .then(() => this.setState({ phone: values.phone }))
      .catch((message) => this.setState({ message }));
  }

  onSubmitCode(values) {
    const { verifySmsCode } = this.props,
      { phone } = this.state;
    verifySmsCode(values);
    values.phone = phone;
  }

  changeHandler(item, values) {}

  render() {
    const { message, phone } = this.state,
      formProps = !phone
        ? {
            fields: {
              phone: {
                name: "phone",
                type: "tel",
                placeholder: "הזן טלפון",
                label: "טלפון",
                required: true,
                autoFocus: true,
              },
            },
            submitValue: "שלח לי קוד",
            submitHandler: this.onSubmitPhone.bind(this),
            changeHandler: this.changeHandler.bind(this),
          }
        : {
            fields: {
              code: {
                name: "code",
                type: "code",
                placeholder: "הזן קוד",
                label: "קוד",
                required: true,
                autoFocus: true,
              },
              rememberMe: {
                name: "rememberMe",
                type: "switch",
                label: "זכור אותי",
                defaultChecked: true,
              },
            },
            submitValue: "כניסה",
            submitHandler: this.onSubmitCode.bind(this),
            changeHandler: this.changeHandler.bind(this),
          };

    return (
      <>
        {phone ? (
          <Icon
            name="arrow-right"
            wrapperClassName={style.back}
            onClick={() =>
              this.setState({ phone: undefined, message: undefined })
            }
          />
        ) : null}
        <h1>כניסה</h1>
        <Form
          colNums={1}
          className={style.loginForm}
          {...formProps}
          message={message}
          messageColor="red"
          data={this.state}
        />
      </>
    );
  }
}

export default connect((state) => ({ user: state.user }), {
  getSmsCode,
  verifySmsCode,
})(LoginForm);
