import React, { Component } from "react";
import style from "./paging.module.scss";
import Select from "../inputs/select";
import Icon from "../icon";

class Paging extends Component {
  constructor() {
    super();
    this.state = {};
  }
  getPagination() {
    const { allLength, from, limit } = this.props,
      pagesNum = Math.ceil(allLength / limit),
      currentPage = from / limit + 1,
      isFirstPage = currentPage === 1,
      isLastPage = currentPage === pagesNum;
    if (pagesNum === 1) return null;
    let nums = [currentPage - 1, currentPage, currentPage + 1].filter(
      (n) => n > 0 && n <= pagesNum
    );
    if (isFirstPage && pagesNum > 2) nums.push(3);
    if (isLastPage && currentPage != 2) nums.unshift(pagesNum - 2);

    return (
      <div className={style.pagination}>
        {isFirstPage ? null : (
          <Icon
            name="angle-double-left"
            onClick={() => this.change(0, limit)}
            size={20}
          />
        )}
        {isFirstPage ? null : (
          <Icon
            name="angle-left"
            onClick={() => this.change(from - limit, limit)}
            size={20}
          />
        )}
        {nums.map((n) => (
          <span
            onClick={() => this.change(limit * (n - 1), limit)}
            className={`${style.paginationNum} ${
              n === currentPage ? style.active : ""
            }`}
            key={n}
          >
            {n}
          </span>
        ))}
        {isLastPage ? null : (
          <Icon
            name="angle-right"
            onClick={() => this.change(from + limit, limit)}
            size={20}
          />
        )}
        {isLastPage ? null : (
          <Icon
            name="angle-double-right"
            onClick={() => this.change((pagesNum - 1) * limit, limit)}
            size={20}
          />
        )}
      </div>
    );
  }
  change(newFrom, newLimit) {
    this.props.onChange(Number(newFrom), Number(newLimit));
  }
  render() {
    const { allLength, from, limit } = this.props,
      pagesNum = Math.ceil(allLength / limit);

    return (
      <div className={style.paging}>
        <div className={pagesNum === 1 ? "" : style.rowsNum}>
          {"הצג"}{"   "}
          <Select
            options={[
              "20",
              "50",
              "75",
              "100",
              "150",
              "200",
              { text: "all", value: 999999 },
            ]}
            defaultValue={limit}
            onValueChange={(val) => this.change(0, val)}
            className={style.select}
          />{" "}
          {"בכל עמוד"}
        </div>
        {allLength ? this.getPagination() : "No Recordes"}
        <div className={pagesNum === 1 ? "" : style.rowsNumView}>
          {allLength <= limit
            ? `${"סה״כ"}:`
            : `${"display"} ${from + 1}-${from + limit} ${"of"}`}{" "}
          {allLength} {"שורות"}
        </div>
      </div>
    );
  }
}

export default Paging;
