import React, { Component } from 'react'
import style from './form.module.scss'
import FormItem from './formItem'
import Icon from '../icon'

class MultipeItem extends Component {
    constructor(props) {
        super(props)
        this.values = [...((props.item || {}).defaultValue || [])]
        this.state = { times: ((props.item || {}).multiple ? this.values.length : 1) }
    }

    render() {
        const { item, onChange } = this.props,
            { ref, label } = item,
            { times } = this.state

        item.multiple = undefined
        const mult = []
        for (let i = 0; i < times; i++) {
            mult.push(<FormItem
                noLabel
                key={ref + i}
                onChange={val => {
                    this.values[i] = val
                    onChange(this.values)
                }}
                item={Object.assign({}, item, { defaultValue: this.values[i] })}
            />)
        }

        return <div className={style.multiple}>
            <label className={style.multipleLabel}>{(label)}</label>
            {mult}
            <Icon className={style.multipleAdd} name='plus' onClick={() => this.setState({ times: times + 1 })} />
        </div>
    }
}

export default MultipeItem