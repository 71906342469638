import React from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { routesList } from "../../../routesList";
import style from "./Navbar.module.scss";
export default function NavBar() {
  return (
    <Navbar expand="lg" className={style.nav}>
      <Container>
        <Navbar.Brand href="/">Tracko</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {routesList.nonLogin.map((route) => (
              <>
                {route.route === "/" && (
                  <Nav.Link key={route.name} href={`${route.route}`}>
                    {route.name}
                  </Nav.Link>
                )}
                {route.route === "/login" && (
                  <button className={style.login} key={route.name}>
                    <Nav.Link
                      href={`${route.route}`}
                      style={{ color: "white", fontSize: "18px" }}
                    >
                      {route.name}
                    </Nav.Link>
                  </button>
                )}
              </>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
