// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Join_Join__v\\+zUk {\n  display: flex;\n  justify-content: space-between;\n  width: 80%;\n  margin: auto; }\n  .Join_Join__v\\+zUk .Join_type__N4sP6 {\n    text-decoration: none;\n    width: 48%;\n    padding: 20px 50px;\n    color: white;\n    font-size: larger;\n    border-radius: 10px; }\n    .Join_Join__v\\+zUk .Join_type__N4sP6:first-child {\n      background-color: #2f97ba; }\n    .Join_Join__v\\+zUk .Join_type__N4sP6:last-child {\n      background-color: #1a1b21; }\n", "",{"version":3,"sources":["webpack://./src/components/NonLoggedin/Join/Join.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;EACV,YAAY,EAAA;EAJd;IAOI,qBAAqB;IACrB,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,mBCXe,EAAA;IDDnB;MAcM,yBChBU,EAAA;IDEhB;MAiBM,yBClBU,EAAA","sourcesContent":["@import \"/src//variables.scss\";\n\n.Join {\n  display: flex;\n  justify-content: space-between;\n  width: 80%;\n  margin: auto;\n\n  .type {\n    text-decoration: none;\n    width: 48%;\n    padding: 20px 50px;\n    color: white;\n    font-size: larger;\n    border-radius: $borderRadius;\n    &:first-child {\n      background-color: $color1;\n    }\n    &:last-child {\n      background-color: $color2;\n    }\n  }\n}\n","$color1: #2f97ba;\n$color2: #1a1b21;\n$errorColor: #ca0000;\n$borderRadius: 10px;\n$isTablet: \"screen and (max-width: 1200px)\";\n$isMobile: \"screen and (max-width: 800px)\";\n$shadow: 0px 3px 20px 3px rgba(0, 0, 0, 0.14);\n$smallShadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),\n  0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);\n$textShadow: 4px 4px 5px rgba(0, 0, 0, 0.2);\n$menuWidth: 220px;\n$mainWidth: 1400px;\n$side: right;\n$opSide: left;\n$direction: rtl;\n$inputHeight:30px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Join": "Join_Join__v+zUk",
	"type": "Join_type__N4sP6"
};
export default ___CSS_LOADER_EXPORT___;
