const regexObj = {
    email: {
        regex: new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/),
        message: 'דוא״ל לא תקין'
    },
    password: {
        regex: new RegExp(/(?=^.{8,}$)((?=.*[0-9])((?=.*[A-Z])|(?=.*[a-z]))).*$/),
        message: 'סיסמא לא תקינה'
    },
    tel: {
        regex: new RegExp(/^[(\d)]{10}$/),
        message: 'מס׳ טלפון לא תקין'
    },
    urlFriendly: {
        regex: new RegExp(/^[a-z_-]*$/),
        message: 'אותיות קטנות באנגלית ומקפים בלבד'
    },
    urlFriendly2:{
        regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
        message:'לא כתובת אינטרנט תיקנית'

    },

    coupon: {
        regex: new RegExp(/^[a-zA-Z0-9_-]*$/),
        message: 'אותיות מספרים ומקפים בלבד'
    }
}

export default (target) => {
    let { value, type, required, min} = target,
        tagName = target.tagName.toLowerCase(),
        message = ''

    if (tagName == 'input' || tagName == 'textarea') {
        const vObj = regexObj[target.getAttribute('vd-type') || type]
        if (!value && required)
            message = 'נא למלא שדה זה'
        else if (vObj && !vObj.regex.test(value))
            message = vObj.message
        else if (parseInt(value) < min)
        message = 'לא יכול להיות ערך שלילי'

    }

    return message
}