import React, { Component } from "react";
import style from "./dropMenu.module.scss";
import Icon from "../icon";

class DropMenu extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { className = "", options = [] } = this.props,
      { open } = this.state;

    return (
      <>
        {open ? (
          <div
            className={style.overlay_1}
            onClick={() => this.setState({ open: false })}
          ></div>
        ) : (
          ""
        )}
        <div className={`${style.dropMenu} ${className}`}>
          <Icon
            className={style.btn}
            name="ellipsis-v"
            onClick={() => this.setState({ open: !open })}
          />
          <div className={`${style.options} ${open ? style.open : ""}`}>
            {options.map((o) => {
              if (typeof o != "object") return null;
              let { text, value, onClick, icon } = o;
              if (typeof value != "string") value = text;
              return (
                <div
                  key={value}
                  onClick={() => {
                    if (onClick) onClick(value);
                    this.setState({ open: false });
                  }}
                  className={style.option}
                >
                  <Icon name={icon} />
                  <span className={style.text}>{text}</span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default DropMenu;
