import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table";
import { deliveryFields } from "../../fields/delivery";
import { getDeliveries } from "../../redux/reducers/deliveries";
import DeliveryForm from "./DeliveryForm";

export default function Deliveries() {
  const dispatch = useDispatch();
  const { deliveries } = useSelector((state) => state.deliveries);

  useEffect(() => {
    dispatch(getDeliveries({}));
  }, [dispatch]);

  return (
      <Table
        data={deliveries}
        AddEditComponent={DeliveryForm}
        fields={deliveryFields}
        collection="delivery"
      />
  );
}
