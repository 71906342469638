// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_nav__2jspF {\n  position: absolute !important;\n  width: 100%; }\n  .Navbar_nav__2jspF .Navbar_login__F0FMj {\n    background-color: #2f97ba;\n    padding: 0 20px;\n    border: none;\n    border-radius: 20px; }\n  .Navbar_nav__2jspF a {\n    color: white !important; }\n", "",{"version":3,"sources":["webpack://./src/components/NonLoggedin/NavBar/Navbar.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AACA;EACE,6BAA6B;EAC7B,WAAW,EAAA;EAFb;IAII,yBCLY;IDMZ,eAAe;IACf,YAAY;IACZ,mBAAmB,EAAA;EAPvB;IAUI,uBAAuB,EAAA","sourcesContent":["@import \"/src/variables.scss\";\n.nav {\n  position: absolute !important;\n  width: 100%;\n  .login {\n    background-color: $color1;\n    padding: 0 20px;\n    border: none;\n    border-radius: 20px;\n  }\n  a{\n    color: white !important;\n  }\n}\n","$color1: #2f97ba;\n$color2: #1a1b21;\n$errorColor: #ca0000;\n$borderRadius: 10px;\n$isTablet: \"screen and (max-width: 1200px)\";\n$isMobile: \"screen and (max-width: 800px)\";\n$shadow: 0px 3px 20px 3px rgba(0, 0, 0, 0.14);\n$smallShadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),\n  0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);\n$textShadow: 4px 4px 5px rgba(0, 0, 0, 0.2);\n$menuWidth: 220px;\n$mainWidth: 1400px;\n$side: right;\n$opSide: left;\n$direction: rtl;\n$inputHeight:30px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "Navbar_nav__2jspF",
	"login": "Navbar_login__F0FMj"
};
export default ___CSS_LOADER_EXPORT___;
