import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/table";
import { orderFields } from "../../fields/order";
import { getOrders } from "../../redux/reducers/orders";

export default function Order() {
  const { orders } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  return (
    <Table
      data={orders}
      AddEditComponent={null}
      fields={orderFields}
      collection="order"
      extra={true}
    />
  );
}
