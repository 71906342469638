const deliveryFields = {
  id: { tableType: "string", type: "text", hide: true, hideOnTable: true },
  active: {
    type: "switch",
    default: true,
    tableType: "boolean",
    label: "פעיל",
    formStep: 1,
  },
  sum: {
    type: "number",
    default: 0,
    tableType: "sum",
    label: "מחיר",
    hide: true,
  },
  deliveryNumber: {
    type: "number",
    tableType: "number",
    label: "מזהה הובלה",
    hide: true,
  },
  pickupAddress: {
    tableType: "string",
    type: "address",
    required: true,
    formStep: 2,
    label: "כתובת איסוף",
  },
  deliveryAddress: {
    tableType: "string",
    type: "address",
    required: true,
    formStep: 3,
    label: "כתובת יעד",
  },
  customer: {
    tableType: "string",
    type: "stribg",
    hide: true,
    // formStep: 3,
    label: "לקוח",
    hideOnTable: true,
  },
  shippingContent: {
    type: "group",
    formStep: 4,
    label: "shipping Content",

    members: {
      surfacesAmount: {
        type: "number",
        tableType: "number",
        name: "surfacesAmount",
        label: "surfaces Amount",
      },
      fragileContents: {
        type: "switch",
        tableType: "boolean",
        name: "fragileContents",
        label: "fragile Contents",
      },
    },

    hideOnTable: true,
  },

  deliveryTime: {
    type: "group",
    required: true,
    formStep: 5,
    hideOnTable: true,
  },
  pickupAddressplaceId: {
    tableType: "string",
    type: "text",
    hide: true,
    hideOnTable: true,
  },
  deliveryAddressplaceId: {
    tableType: "string",
    type: "text",
    hide: true,
    hideOnTable: true,
  },
  status: {
    type: "select",
    tableType: "string",
    options: [
      { text: "new", value: "new" },
      { text: "businessSelected", value: "businessSelected" },
      { text: "driverAssined", value: "driverAssined" },
      { text: "picked", value: "picked" },
      { text: "done", value: "done" },
      { text: "open", value: "open" },
      { text: "archive", value: "archive" },
    ],
    default: "new",
    hide: true,
    label: "סטטוס",
  },
  userPhone: {
    tableType: "string",
    type: "text",
    hide: true,
    status: "user phone",
    hideOnTable: true,
  },
  distance: {
    type: "string",
    tableType: "string",
    hide: true,
    label: "מרחק",
  },
  duration: {
    type: "string",
    tableType: "string",
    hide: true,
    label: "זמן הגעה",
  },
  deliveryCompany: {
    type: "string",
    tableType: "string",
    hide: true,
    label: "חברת הובלה",
  },
  driver: {
    type: "string",
    tableType: "string",
    hide: true,
    label: "נהג מוצמד",
  },
};

Object.keys(deliveryFields).map((key) => (deliveryFields[key].name = key));

export { deliveryFields };
