export const __DEV__ = process.env.NODE_ENV !== "production";

export const prodBaseUrl = "https://api.trado.co.il";
export const devBaseUrl = "http://localhost:4000/api/v1";
export const trakoBaseUrl = "https://api-app.trado.co.il";

export const LOCAL_SOCKET_URL = "http://localhost:4000";
export const PROD_SOCKET_URL = "https://api-app.trado.co.il";

export const baseUrl = __DEV__ ? devBaseUrl : trakoBaseUrl;
export const sockerURL = __DEV__ ? LOCAL_SOCKET_URL : PROD_SOCKET_URL;

const testBaseUrl = "https://qa-trado-server.herokuapp.com";

//export const baseUrl = `${localStorage.bu1200 || (__DEV__ ? devBaseUrl : prodBaseUrl)}/api/`;

// export const trakoBaseUrlDev = "http://localhost:4000";
// export const imgsUrl = "https://storage.googleapis.com/mm-imgs/";
export const imgsUrl = "https://storage.cloud.google.com/trado_images/";
export const imagesUrl = (name) => `${imgsUrl}${name}`;
export const GOOGLE_API_KEY = "AIzaSyAatuuUBCujhP0SnI803hJRzOP2OP02ymM";

export const mobileWidth = 768;
