import React from "react";
import Icon from "../../icon";
import SearchAdress from "../../inputs/Adress";
import Input from "../../inputs/input";
import Select from "../../inputs/select";
import NavBar from "../NavBar";
import style from "./Header.module.scss";

export default function MainHeader() {
  const changeHandler = (val) => {
    console.log(val);
  };

  return (
    <div className={style.header}>
      <NavBar />
      <img src="/assets/shane-mclendon.png" alt="" className={style.heroImg} />
      <section className={style.formContainer}>
        <h1 style={{ color: "white", fontWeight: "bold", fontSize: 37 }}>
          מאתרים עבורך את המוביל המתאים ביותר למשימה
        </h1>
        <p style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>
          טרדו מחברת בין עסקים לבין אלפי מובילי משא כבד בפריסה ארצית באמצעות
          מערכת חכמה
        </p>
        <form className={style.form}>
          <article>
            <div>
              <img src="/assets/icons/Icon feather-ma.svg" alt="" />
              <span>נקודת איסוף</span>
            </div>
            <SearchAdress
              label="נקודת איסוף"
              placeholder="הזן את כתובת האיסוף"
              onChange={changeHandler}
            />
          </article>
          <article>
            <div>
              <img src="/assets/icons/Icon feather-ma.svg" alt="" />
              <span>נקודת מסירה</span>
            </div>
            <SearchAdress
              label="נקודת מסירה"
              placeholder="הזן את כתובת המסירה"
              onChange={changeHandler}
            />
          </article>

          <article>
            <div>
              <img src="/assets/icons/Icon feather-ca.svg" alt="" />
              <span>מועד מסירה</span>
            </div>
            <input type="date" onChange={changeHandler} />
          </article>

          <article>
            <div>
              <img src="/assets/icons/Icon feather-ca.svg" alt="" />
              <span>שעת מסירה</span>
            </div>
            <input type="time" onChange={changeHandler} />
          </article>

          <article>
            <div>
              <img src="/assets/icons/Box Icon.svg" alt="" />
              <span>בטון</span>
            </div>
            <select name="shippingContent" id="shippingContent">
                <option value="בטון">בטון</option>
                <option value="עפר">עפר</option>
            </select>
            {/* <Select options={["עפר", "בטון"]} onChange={changeHandler} /> */}
          </article>
          {/* <article>
            <div>
              <img src="/assets/icons/Box Icon.svg" alt="" />
            </div>
            <input
              type="number"
              onChange={changeHandler}
              placeholder="הכנס כמות"
            />
          </article> */}
          <div className={style.submitBtnContainer}>
            <input
              type="submit"
              value={"מצא לי מוביל"}
              className={style.submitBtn}
            />
          </div>
        </form>
      </section>
    </div>
  );
}
