import { configureStore } from "@reduxjs/toolkit";
import user from "./reducers/user";
import employees from "./reducers/employee";
import deliveries from "./reducers/deliveries";
import dashboard from "./reducers/dashboard";
import config from "./reducers/config";
import business from "./reducers/business";
import orders from "./reducers/orders";

const store = configureStore({
  reducer: {
    user,
    employees,
    deliveries,
    dashboard,
    business,
    config,
    orders
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
});
export default store;
