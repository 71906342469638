import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { getUserById } from "./redux/reducers/user";
import { routesList } from "./routesList";
import style from "./pages.module.scss";
import Menu from "./components/menu";
import AdminLAyout from "./Layouts/Admin";
import SingleOrder from "./pages/orders/SingleOrder";
import Businesses from "./pages/businesses";

function AppRoute() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { isMobile } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(getUserById());
  }, [dispatch]);

  return (
    <div className={style.pages}>
      <Router>
        <header>{user?.id && <Menu />}</header>
        {!user?.id ? (
          <main>
            <Routes>
              {routesList.nonLogin.map((route) => {
                return (
                  <Route
                    path={route.route}
                    element={<route.component />}
                    key={route.route}
                  />
                );
              })}
              {/* <Route path="*" element={<Navigate to={"/"} replace />} /> */}
            </Routes>
          </main>
        ) : (
          <AdminLAyout>
            <main
              className={`${style.main} ${isMobile ? style.fullScreen : ""}`}
            >
              <Routes>
                <>
                  {routesList.routes.map((route) => {
                    return (
                      <Route
                        path={route.route}
                        element={<route.component />}
                        key={route.route}
                      />
                    );
                  })}
                </>
                <Route path="/order/:id" element={<SingleOrder />} />
                <Route path="/business/:id" element={<Businesses />} />
                <Route path="*" element={<Navigate to={"/"} replace />} />
              </Routes>
            </main>
          </AdminLAyout>
        )}
      </Router>
    </div>
  );
}
export default AppRoute;
