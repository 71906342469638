import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Form from "../../components/form";
import { baseUrl } from "../../config";
import { employeeFields } from "../../fields/employee";
import {
  addNewEmployee,
  updateEmployeeById,
} from "../../redux/reducers/employee";
import { httpRequestServise } from "../../services/http.service";

function EmployeeForm(props) {
  const { data = {}, collection, action, setSelectedItem } = props;
  const dispatch = useDispatch();

  let employee = data || {};

  useEffect(() => {
    getEmployess();
  }, [data]);

  const getEmployess = async () => {
    const data = await httpRequestServise(`${baseUrl}/business`, "get", {});
    const options = [];
    data.map((business) =>
      options.push({ text: business.name, value: business.id })
    );
    console.log(options);
    employeeFields.business.options = options;
  };

  const submitHandler = async (values) => {
    let res;
    if (action === "create") res=await dispatch(addNewEmployee(values));
    else
     res=await dispatch(updateEmployeeById({ id: data.id, values }));
     if (res.meta?.requestStatus == "fulfilled") {
      setTimeout(() => setSelectedItem(null), 300);
    }
  };

  const changeHandler = (item, values) => {
    // console.log(item);
  };

  return (
    <div>
      <Form
        data={employee}
        fields={employeeFields}
        action={action}
        collection={collection}
        submitHandler={submitHandler}
        changeHandler={changeHandler}
      />
    </div>
  );
}

export default EmployeeForm;
