import React from "react";
import styles from "./Adress.module.scss";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { GOOGLE_API_KEY } from "../../../config";

export default function SearchAdress(props) {
  const { onChange, placeholder, value } = props;
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
    language: "he",
    options: {
      types: ["locality"],
    },
  });
  return (
    <div className={styles.search_adress}>
      <input
        placeholder={placeholder || "הכנס כתובת"}
        value={value}
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
        }}
        loading={isPlacePredictionsLoading}
      />
      <article className={styles.options}>
        {placePredictions?.map((place) => {
          return (
            <p
              key={place.place_id}
              onClick={() => {
                console.log(place);
                onChange({
                  address: place.description,
                  placeId: place.place_id,
                });
              }}
            >
              {place.description}
            </p>
          );
        })}
      </article>
    </div>
  );
}
