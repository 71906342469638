import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpRequestServise } from "../../services/http.service";
import { baseUrl } from "../../config";

export const getDashboard = createAsyncThunk("dashboard", async () => {
  return httpRequestServise(`${baseUrl}/dashboard`, "get");
});

const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState: {
    deliveriesByDate: [],
    counts: [],
  },
  extraReducers: {
    [getDashboard.fulfilled]: (state, { payload }) => {
      state.deliveriesByDate = payload.deliveriesByDate;
      state.counts = payload.counts;
      return state
    },
  },
});

export default dashBoardSlice.reducer;
