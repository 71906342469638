import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Form from "../../../components/form";
import Title from "../../../components/title";
import { httpRequestServise } from "../../../services/http.service";
import { baseUrl } from "../../../config";
import style from "./Joinus.module.scss";

export default function Joinus() {
  const { type } = useParams();
  const [formValues, setFormValues] = useState({
    type: type === "business" ? "customer" : "delivery_company",
  });

  const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false);

  const fields = {
    name: { type: "text", required: true, name: "name" },
    email: { type: "email", required: true, name: "email" },
    phone: { type: "text", required: true, name: "phone" },
  };

  const changeHandler = () => {};

  const submitHandler = async (values) => {
    try {
      const data = await httpRequestServise(
        `${baseUrl}/business`,
        "post",
        values
      );
      const { business, authToken } = data;
      localStorage.authToken = authToken;
      localStorage.uid = business.id;
      localStorage.entity = "business";
      localStorage.business = business.business?.name || business.name;
      setTimeout(() => setRegisteredSuccessfully(true), 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={style.Joinus}>
      {registeredSuccessfully && <Navigate replace to={"/"} />}
      <section className={style.formContainer}>
        <Title>
          <h1>הירשם כ{type === "business" ? "בעל עסק" : "חברת הובלות"}</h1>
        </Title>
        <Form
          data={formValues}
          fields={fields}
          submitHandler={submitHandler}
          changeHandler={changeHandler}
          submitValue="הרשם"
        />
      </section>
    </div>
  );
}
