import React, { useEffect, useState } from "react";
import FormItem from "./formItem";
import style from "./form.module.scss";
import Timeline from "../Timeline";

export default function Form(props) {
  const {
    fields = {},
    data,
    submitHandler,
    submitValue,
    changeHandler,
    className,
    multiStep = { steps: [] },
    colNums = 2,
    message,
    messageColor = "red",
    collection,
  } = props;

  const values = data || {};
  const [step, setStep] = useState(0);
  const [stepsObj, setStepsObj] = useState([]);

  useEffect(() => {
    // turn the steps into objects and add time stemp
    const tempStep = [];
    multiStep.steps.map((element) => {
      const obj = { text: element, time: Date.now() };
      tempStep.push(obj);
    });
    setStepsObj(tempStep);
  }, []);

  const createFormItem = (item) => {
    let { hide, name } = item;
    if (hide) return;
    item.placeholder = item.placeholder;
    return !item.formStep || item.formStep === step + 1 ? (
      <FormItem
        key={item.name}
        item={item}
        fields={fields}
        onChange={(val) => {
          if (item.type === "file") {
            if (!values.files) values.files = {};
            values.files[name] = val;
          } else values[name] = val;
          changeHandler(item, values);
        }}
        colNums={colNums}
      />
    ) : null;
  };

  const submit = (e) => {
    e.preventDefault();
    submitHandler(values);
  };

  return (
    <>
      <form onSubmit={submit} className={`${style.form}`}>
        {multiStep.steps.length > 0 && (
          <Timeline activeIndex={step} steps={stepsObj} />
        )}
        <div className={style.items} style={{ width: `${colNums * 320}px` }}>
          {Object.keys(fields).map((item) => {
            fields[item].defaultValue = fields[item].ref || data[item];
            return createFormItem(fields[item]);
          })}
        </div>
        <div
          className={style.message}
          style={messageColor ? { color: messageColor } : {}}
        >
          {message}
        </div>

        {/* buttons on multistep or not multistep */}
        {!multiStep.steps.length ? (
          <button type="submit" className={style.submitBtn}>
            {submitValue || "שמור"}
          </button>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              disabled={step === 0}
              name="back"
              type="button"
              onClick={(e) => {
                if (step !== 0) setStep(step - 1);
                stepsObj[step - 1]["time"] = Date.now();
              }}
              className={style.submitBtn}
              // value=
            >
              {"back"}
            </button>
            <button
              type={step < multiStep.steps.length ? "button" : "submit"}
              className={style.submitBtn}
              onClick={(e) => {
                if (step < multiStep.steps.length) setStep(step + 1);
                stepsObj[step + 1]["time"] = Date.now();
              }}
            >
              {step < multiStep.steps.length - 1
                ? "next"
                : submitValue || "send"}
            </button>
          </div>
        )}
      </form>
    </>
  );
}
