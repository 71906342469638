import React, { useState } from "react";
import style from "./Switch.module.scss";

export default function Switch(props) {
  const { constents, onClick, initIndex } = props;
  const [activeIndex, setActiveIndex] = useState(initIndex || 0);

  return (
    <div className={style.switch}>
      <div className={style.switchHeader}>
        {constents.map((con, index) => (
          <div
            onClick={() => {
              setActiveIndex(index);
              onClick(con);
            }}
            className={`${style.option} ${
              index === activeIndex && style.active
            }`}
          >
            <p>{con.title}</p>
          </div>
        ))}
      </div>
      {/* <div>{constents[activeIndex].comp}</div> */}
    </div>
  );
}
