import { useEffect, useState } from "react";
import socketIo from "socket.io-client";
import { sockerURL } from "../config";

const useSocket = (serverUrl = sockerURL, topic) => {
  const [isConnected, setIsConnected] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const client = socketIo(serverUrl);
    client.on("connect", () => setIsConnected(true));
    client.on("disconnect", () => setIsConnected(false));
    client.on(topic, (data) => {
      if (topic !== "joinRoom") {
        setData(data);
      }
    });
    return () => {};
  }, [isConnected, topic, serverUrl]);
  return { data, isConnected,topic };
};
export default useSocket;
