const employeeFields = {
  id: {
    type: "text",
    required: true,
    hide: true,
    hideOnTable: true,
    tableType: "string",
  },
  name: {
    type: "text",
    name: "name",
    label: "שם",
    required: true,
    tableType: "string",
  },
  phone: {
    type: "text",
    name: "phone",
    label: "טלפון",
    required: true,
    tableType: "string",
  },
  role: {
    type: "select",
    name: "role",
    label: "תפקיד",
    tableType: "string",
    options: ["driver", "manager", "admin"],
    required: true,
  },
  business: {
    type: "select",
    name: "business",
    label: "עסק",
    tableType: "string",
    options: [],
    required: true,
  },
  profileImage: {
    type: "file",
    name: "profileImage",
    label: "תמונת פרופיל",
    tableType: "image",
    // required: false,
  },
  // deliveries: [{ type: mongoose.Types.ObjectId, ref: "Delivery" }],
};

Object.keys(employeeFields).map((key) => (employeeFields[key].name = key));

export { employeeFields };
