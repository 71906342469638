// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Joinus_Joinus__t053e {\n  height: 93vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #e9ebee; }\n  .Joinus_Joinus__t053e .Joinus_formContainer__55SjM {\n    width: 500px;\n    background-color: white;\n    border-radius: 15px; }\n", "",{"version":3,"sources":["webpack://./src/pages/NonLogedIn/Joinus/Joinus.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB,EAAA;EAN3B;IASI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB,EAAA","sourcesContent":[".Joinus {\n  height: 93vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #e9ebee;\n\n  .formContainer {\n    width: 500px;\n    background-color: white;\n    border-radius: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Joinus": "Joinus_Joinus__t053e",
	"formContainer": "Joinus_formContainer__55SjM"
};
export default ___CSS_LOADER_EXPORT___;
