import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../icon";
import Title from "../../title";
import style from "./Join.module.scss";

export default function Join() {
  return (
    <>
      <Title>
        <h2>Join Us</h2>
      </Title>
      <article className={style.Join}>
        <Link to="join/deliveryCompany" className={style.type}>
          <Icon name="truck" style={{ fontSize: "100px", color:"white" }} size={100}/>
          <h2>הצטרף כחברת הובלות</h2>
          <p>פחות כאב ראש, יותר כסף לכיס.</p>
        </Link>
        <Link to="join/business" className={style.type}>
          <Icon name="briefcase" style={{ fontSize: "100px", color:"white" }} size={100}/>
          <h2>הצטרף כבעל עסק</h2>
          <p>מתקדמים לשרשרת אספקה יעילה וחסכונית.</p>
        </Link>
      </article>
    </>
  );
}
