import React from 'react';
import style from './TimeLine.module.scss';
import { _dateToString } from '../../helpers';

export default function Timeline(props) {
  const { activeIndex, steps } = props;

  return (
    <div className={style.Timeline}>
      {steps.map((step, i) => {
        return (
          <div key={step.text} className={`${style.time} ${i < activeIndex ? style.full : i === activeIndex ? style.current : ''}`}>
            <div className={style.circle}>{i < activeIndex ? '✓' : i + 1}</div>
            <span className={style.name}>{(step['text'])}</span>
            <span className={style.date}>{i <= activeIndex ? _dateToString(step['time']) : ''}</span>
          </div>
        );
      })}
    </div>
  );
}
