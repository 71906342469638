import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import { httpRequestServise } from "../../services/http.service";

export const getOrders = createAsyncThunk(
  "getOrders request",
  async (filter = {}) => {
    return httpRequestServise(`${baseUrl}/order`, "get", {}, filter);
  }
);

export const addNewOrder = createAsyncThunk("request name", async (body) => {
  return httpRequestServise(`${baseUrl}/order`, "post", body);
});

export const updateOrderById = createAsyncThunk(
  "request name",
  async ({ id, body }) => {
    return httpRequestServise(`${baseUrl}/order/${id}`, "patch", body);
  }
);

const orderSlice = createSlice({
  name: "orders",
  initialState: {
    loading: true,
    orders: [],
  },
  reducers: {
    newOrderBySocket: (state, { payload }) => {
      state.orders = [...state.orders, payload];
      return state;
    },
  },
  extraReducers: {
    [getOrders.fulfilled]: (state, { payload }) => {
      state.orders = payload;
      state.orders?.map((order) => {
        order.orderCompany = order.orderCompany?.name;
        order.driver = order.driver?.name;
        return order;
      });
      return state;
    },
    [addNewOrder.fulfilled]: (state, { payload }) => {
      state = [...state.orders, payload];
      return state;
    },
    [updateOrderById.fulfilled]: (state, { payload }) => {
      const index = state.orders.indexOf((item) => item.id === payload.id);
      state.orders[index] = payload;
      return;
    },
  },
});

export const { newOrderBySocket } = orderSlice.actions;

export default orderSlice.reducer;
