import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import GoogleMap from "../../../components/googleMap";
import { getDeliveries } from "../../../redux/reducers/deliveries";

function DeliveryDashboard() {
  const dispatch = useDispatch();
  const { deliveries } = useSelector((state) => state.deliveries);

  useEffect(() => {
    dispatch(getDeliveries());
  }, [dispatch]);
  return (
    <>
      <GoogleMap data={deliveries} />
    </>
  );
}

export default connect((state) => ({}), {})(DeliveryDashboard);
