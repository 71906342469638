import React, { Component } from "react";
import style from "./select.module.scss";
import Icon from "../../icon";
class Select extends Component {
  constructor() {
    super();
    this.state = {};
  }
  // onChange(e) {
  //
  //   onChange
  //   // if (onChange) onChange(e);
  //   // if (onChange) onChange(e.target.value);
  // }
  render() {
    const { onChange } = this.props;
    let {
      options = [],
      forSurfaces,
      name,
      defaultValue,
      required,
    } = this.props;
    options = options.map((option) => {
      if (typeof option != "object") option = { text: option };
      let { text, value } = option;
      if (typeof value != "string" && typeof value != "number") value = text;
      text = text || text;
      return { text, value };
    });

    return (
      <div className={`${style.select} ${forSurfaces ? style.surfaces : ""} `}>
        <select
          name={name}
          id={name}
          onChange={(e) => onChange(e.target.value)}
          defaultValue={defaultValue}
        >
          {options.map((option) => {
            return (
              <option
                key={option.text}
                value={option.value}
                // selected={option.value === defaultValue}
              >
                {option.text}
              </option>
            );
          })}
        </select>
        <Icon name="angle-down" className={style.icon} />
      </div>
    );
  }
}

export default Select;
