// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Testimony_testimonies__BBX3J {\n  display: flex;\n  justify-content: center;\n  width: 80%;\n  margin: auto; }\n  .Testimony_testimonies__BBX3J .Testimony_testimony__BfFN6 {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 25%; }\n    .Testimony_testimonies__BBX3J .Testimony_testimony__BfFN6 img {\n      width: 150px;\n      height: 150px;\n      border-radius: 50%; }\n    .Testimony_testimonies__BBX3J .Testimony_testimony__BfFN6 .Testimony_description__DJlzM {\n      margin-top: 10px;\n      font-size: 18px; }\n", "",{"version":3,"sources":["webpack://./src/components/NonLoggedin/Testimony/Testimony.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,UAAU;EACV,YAAY,EAAA;EAJd;IAMI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IAEnB,UAAU,EAAA;IAXd;MAaM,YAAY;MACZ,aAAa;MACb,kBAAkB,EAAA;IAfxB;MAkBQ,gBAAgB;MAChB,eAAe,EAAA","sourcesContent":[".testimonies {\n  display: flex;\n  justify-content: center;\n  width: 80%;\n  margin: auto;\n  .testimony {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    width: 25%;\n    img {\n      width: 150px;\n      height: 150px;\n      border-radius: 50%;\n    }\n    .description{\n        margin-top: 10px;\n        font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"testimonies": "Testimony_testimonies__BBX3J",
	"testimony": "Testimony_testimony__BfFN6",
	"description": "Testimony_description__DJlzM"
};
export default ___CSS_LOADER_EXPORT___;
