import React, { Component } from "react";
import style from "./menu.module.scss";
import { connect } from "react-redux";
import Icon from "../icon";
import { routesList } from "../../routesList";
import { toggleMenu } from "../../redux/reducers/config";
import { Link } from "react-router-dom";

class Menu extends Component {
  constructor() {
    super();
    this.state = {};
  }
  closeMenu() {
    const { toggleMenu, isMobile, menuOpen } = this.props;
    toggleMenu();
    console.log({ isMobile, menuOpen });
  }

  render() {
    const { menuOpen, isMobile, user } = this.props;

    return (
      <div
        className={menuOpen ? style.overlay : ""}
        // handles on mobile
        onClick={(e) => {
          if (e.target === e.currentTarget) this.closeMenu();
        }}
      >
        <nav className={`${style.menu} ${menuOpen ? style.open : ""}`}>
        <div className={style.user}> 
          <Icon name='user' className={style.icon} />
          <span className={style.hello}>{('שלום')} {user.user.name}</span>
        </div> 
        <hr></hr>
          <div className={style.scroll}>
            {routesList.routes.map(
              (page) =>
                !page.notDispley && (
                  <>
                    {page.data ? (
                      <div className={style.spr}>{"Data"}</div>
                    ) : user.role === "owner" && page.path === "subCategories" ? (
                      <div className={style.spr}>{"system management"}</div>
                    ) : user.role !== "store" && page.analytics ? (
                      <div className={style.spr}>{"Analytics"}</div>
                    ) : user.role !== "store" && page.delivery ? (
                      <div className={style.spr}>{"Trado Delivery"}</div>
                    ) : null}
                    <Link
                      onClick={() => {
                        if (isMobile) this.closeMenu();
                      }}
                      activeClassName={style.active}
                      to={page.route || page.route}
                      className={`${style.menuItem} ${
                        page.disable ? style.disable : ""
                      }`}
                      key={page.name}
                    >
                      <Icon name={page.icon} size={30} className={style.icon} />
                      <span>{page.name}</span>
                    </Link>
                  </>
                )
            )}
          </div>
        </nav>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    menuOpen: state.config.menuOpen,
    isMobile: state.config.isMobile,
    user: state.user,
  }),
  { toggleMenu }
)(Menu);
