import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

function GoogleMap(props) {
  const { data = [] } = props;
  const [deliveries, setDeliveries] = useState([]);

  const [map, setmap] = useState(null);
  const defaulSettinf = {
    center: {
      lat: 31.88688,
      lng: 34.7864,
    },
    zoom: 12,
  };

  useEffect(() => {
    if (data) {
      if (!Array.isArray(data)) {
        setDeliveries([data]);
      } else setDeliveries(data);
    }
  }, [data]);

  useEffect(() => {
    deliveries.map((delivery) => getDeliveryPolylineRoute(delivery));
  }, [deliveries]);

  const getDeliveryPolylineRoute = async (delivery) => {
    const { pickupAddressplaceId, deliveryAddressplaceId } = delivery;
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    await directionsService.route(
      {
        origin: { placeId: pickupAddressplaceId },
        destination: { placeId: deliveryAddressplaceId },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      async (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAatuuUBCujhP0SnI803hJRzOP2OP02ymM",
          language: "he",
        }}
        defaultCenter={defaulSettinf.center}
        defaultZoom={defaulSettinf.zoom}
        center={defaulSettinf.center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setmap(map);
        }}
      ></GoogleMapReact>
    </div>
  );
}

export default GoogleMap;
