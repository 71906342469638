import { useDispatch } from "react-redux";

import { useEffect } from "react";
import { sockerURL } from "../../config";
import useSocket from "../../hooks/useSocket";
import { newDeliveryBySocket } from "../../redux/reducers/deliveries";

export default function AdminLAyout({ children }) {
  const dispatch = useDispatch();
  const newDelivery = useSocket(sockerURL, "newOrderCreated");

  useEffect(() => {
    if (newDelivery.data) {
      console.log(newDelivery.topic);
      switch (newDelivery.topic) {
        case "newOrderCreated":
          return dispatch(newDeliveryBySocket(newDelivery.data));
        default:
          break;
      }
    }
  }, [newDelivery]);

  return <div>{children}</div>;
}
