import React from "react";
import Title from "../../title";
import style from "./services.module.scss";
import SingleService from "./SingleService";

export default function Services() {
  const servicesList = [
    {
      title: "איכות הנהגים ביצוע איתור מוביל חכם בעזרת טכנולוגיה מתקדמת",
      image: "/assets/CompositeLayer.png",
      description:
        "מרגע סיום מילוי כלל הפרטים הנדרשים, תוך זמן קצר תוכלו לקבל. הצעות מחיר ישירות מהמובילים לטלפון הנייד שלכם",
      button: { text: "מתאים לי! בואו נתחיל", link: "/" },
    },
    {
      title: "התראות בזמן אמת על ההובלה שלכם",
      image: "/assets/Group 10225.png",
      description:
        "הנהג מתעכב? יש פקקים בדרך? המערכת מתריעה על כל בעיה בזמן אמת.",
      button: { text: "לוחצים כאן ומזמינים הובלה", link: "/" },
    },
    {
      title: "נהגים איכותיים ללא פשרות",
      image: "/assets/Group 10232.png",
      description:
        "כל אחד מהמובילים עובר אצלנו בקרת איכות קפדנית. ואם יש .בעיה, אנחנו זמינים 24/7",
      button: { text: "שמעתי מספיק, אפשר להתחיל ):", link: "/" },
    },
  ];
  return (
    <section>
      <article className={style.services}>
        {servicesList.map((service, index) => (
          <SingleService service={service} key={index} />
        ))}
      </article>
    </section>
  );
}
