import React, { Component } from "react";
import style from "./form.module.scss";
import Input from "../inputs/input";
import Select from "../inputs/select";
import Textarea from "../inputs/textarea";
import Checkbox from "../inputs/checkbox";
import FileInput from "../inputs/fileInput";
import GroupItem from "./groupItem";
import MultipleItem from "./multipleItem";
import validation from "./validation";
import SerchAdress from "../inputs/Adress";

class FormItem extends Component {
  constructor(props) {
    super(props);
    this.state = { note: "" };
  }

  validate(e) {
    this.setState({ note: validation(e.target) });
    e.stopPropagation();
  }

  clearNote() {
    this.setState({ note: "" });
  }

  render() {
    const {
      className = "",
      item,
      onChange,
      colNums = 2,
      noLabel,
      fields,
    } = this.props;
    const {
      type,
      component,
      name,
      multiple,
      label,
      hide,
      placeholder,
      required,
    } = item;
    const { note } = this.state;

    if (hide) return null;
    if (multiple) return <MultipleItem {...this.props} />;
    let Item = null,
      formItemProps = { clearNote: this.clearNote.bind(this) };
    delete item.tabletype;
    switch (type) {
      case "group":
        Item = GroupItem;
        formItemProps = { ...formItemProps, fields };
        break;
      case "component":
        Item = component;
        break;
      case "switch":
      case "checkbox":
        Item = Checkbox;
        formItemProps = { ...formItemProps, switchMode: type === "switch" };
        break;
      case "textarea":
        Item = Textarea;
        break;
      case "address":
        Item = SerchAdress;
        break;
      case "file":
      case "image":
        Item = FileInput;
        break;
      case "select":
        if (!item.readOnly) {
          Item = Select;
          break;
        }
      default:
        Item = Input;

        break;
    }

    return (
      <div
        onChange={this.validate.bind(this)}
        onBlur={this.validate.bind(this)}
        key={name + type + label}
        className={`${style.formItem} ${style[type || "text"]} ${
          name === "active" ? style.activeKey : ""
        }  ${item.className} ${className} formItem_${name}`}
        // style={Object.assign({}, item.style)}
      >
        {["checkbox", "switch"].includes(type) || noLabel ? null : (
          <label>
            {label || name}
            {required ? "*" : ""}
          </label>
        )}
        <Item
          {...item}
          key={name}
          onChange={onChange}
          {...formItemProps}
          placeholder={placeholder}
        />
        <div className={`${style.note} ${note ? "" : style.hidden}`}>
          {note}
        </div>
      </div>
    );
  }
}

export default FormItem;
