import React, { useEffect, useState } from "react";
import style from "./table.module.scss";
import { contentByType } from "../../helpers";
import Modal from "../modal";
import DropMenu from "../dropMenu";
import Paging from "../paging";
import { httpRequestServise } from "../../services/http.service";
import { baseUrl } from "../../config";
import { Link } from "react-router-dom";

function Table(props) {
  const {
    data = [],
    fields,
    AddEditComponent,
    collection,
    extra,
    fromSingle,
  } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState("create");

  const [filter, setFilter] = useState({
    page: 5,
    limit: 20,
    searchTearm: "",
    searchBy: null,
  });

  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    setSearchResult(data);
  }, [data]);

  useEffect(() => {
    search();
  }, [filter]);

  const search = async () => {
    const response = await httpRequestServise(
      `${baseUrl}/${collection}`,
      "get",
      {},
      filter
    );
  };

  return (
    <>
      <div>
        <div className={style.topRow}>
          <DropMenu
            className={style.dropMenu}
            options={[
              {
                text: "הוספה",
                onClick: () => {
                  setAction("create");
                  if (selectedItem) setSelectedItem(null);
                  else setSelectedItem({});
                },
                icon: "plus",
              },
            ]}
          />
        </div>

        <div className={style.tableScroll}>
          <div className={style.table}>
            <table>
              <thead>
                <tr>
                  {Object.keys(fields).map((column) =>
                    fields[column].tableType && !fields[column].hideOnTable ? (
                      <th key={column}>{fields[column].label || column}</th>
                    ) : null
                  )}
                  {extra && <th></th>}
                </tr>
              </thead>
              <tbody>
                {searchResult?.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => {
                      setAction("update");
                      if (selectedItem) setSelectedItem(null);
                      else setSelectedItem(item);
                    }}
                  >
                    {Object.keys(fields).map((column) => {
                      // console.log(item[column]);
                      if (typeof item[column] === "object" &&  item[column] !== "undefined") {
                        console.log(item[column]);
                        // item = item[column]?.name;
                        // column = `${column}.name`;
                        // console.log(fields[`${column}.name`]);
                      }
                      return fields[column].tableType &&
                        !fields[column].hideOnTable ? (
                        <td key={column + "1"}>
                          {contentByType(item, {
                            key: column.id ? column.name : column,
                            type: fields[column].tableType,
                          })}
                        </td>
                      ) : null;
                    })}
                    {extra && (
                      <td>
                        <Link to={`/${collection}/${item.id}`}>הרחב</Link>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Paging
            allLength={data.length}
            from={filter.page}
            limit={filter.limit}
            onChange={(from, limit) => {
              console.log({ from, limit });
            }}
          />
        </div>
        {selectedItem && AddEditComponent && (
          <Modal
            content={
              <AddEditComponent
                data={{ ...selectedItem }}
                fromSingle={fromSingle ? fromSingle : null}
                fields={fields}
                action={action}
                collection={collection}
                setSelectedItem={setSelectedItem}
              />
            }
            options={{
              title: `${action} ${
                action === "create" ? "new" : ""
              } ${collection}`,
            }}
          />
        )}
      </div>{" "}
    </>
  );
}

export default Table;
