import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDashboard } from "../../redux/reducers/dashboard";
import Icon from "../../components/icon";
import style from "./dashboard.module.scss";
import BarChart from "../../components/charts/BarChart";

function Home() {
  const dispatch = useDispatch();
  const { counts, deliveriesByDate } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboard());
    return () => {};
  }, [dispatch]);

  const icons = {
    deliveries: "store",
    employees: "user-friends",
  };

  return (
    <section className={style.dashboard}>
      {console.log(counts)}
      <article className={style.counts}>
        {counts.map((c, index) => {
          const cName = c.name;

          // `${c.name}${c.name.endsWith("s") ? "" : "s"}`;
          return (
            <Link
              to={cName}
              className={`${style.count} ${index < 2 ? style.half : ""}`}
              key={c.name}
            >
              <span className={style.title}>{cName.replace("-", " ")}</span>
              <div>
                <Icon
                  name={icons[cName] || "user-friends"}
                  size={30}
                  className={style.icon}
                />
                <span>|</span>
                <span className={style.countNumber}>
                  {c.inactive
                    ? c.count - c.inactive + " פעילים מתוך  " + c.count
                    : c.count}
                </span>
              </div>
            </Link>
          );
        })}
      </article>
      <article style={{"width": '85%'}}>
        <BarChart data={deliveriesByDate} label={"Deliveries"} />
      </article>
    </section>
  );
}

export default Home;
